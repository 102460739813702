import { computed } from 'vue';
import isArray from 'lodash/isArray';
import { usePagesStore } from '~/stores/pages';

export function usePages() {
  const pagesStore = usePagesStore();

  const currentPage = computed(() => pagesStore.currentPage);

  const displayBreadcrumbs = computed(() => {
    return currentPage.value && !currentPage.value.hide_breadcrumbs;
  });

  const sections = computed(() => {
    return currentPage.value && isArray(currentPage.value.sections) ? currentPage.value.sections : [];
  });

  const hasHeroBlock = computed(() => {
    if (!sections.value.length) {
      return false;
    }
    return ['block_hero', 'block_hero_cta', 'block_hero_simple'].includes(sections.value[0].type);
  });

  const displayPageTitle = computed(() => {
    return !(hasHeroBlock.value || !currentPage.value || currentPage.value.hide_page_title);
  });

  const displayNavigation = computed(() => {
    return currentPage.value && !currentPage.value.hide_navigation;
  });

  return {
    currentPage,
    displayBreadcrumbs,
    sections,
    hasHeroBlock,
    displayPageTitle,
    displayNavigation,
  };
}
